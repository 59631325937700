import React from 'react'
import Flexbox from 'flexbox-react'
import {graphql, StaticQuery} from 'gatsby'
import Img from 'gatsby-image'
import 'react-id-swiper/src/styles/css/swiper.css'
import {Container, Row, Col} from 'reactstrap'

import {
    FaGlobe,
    FaUser,
    FaChartBar
} from 'react-icons/fa'

import {
    SectionTitle,
    SectionFeature,
    Typography
} from '../components'

import {
    colorAccent,
    largeSpacing
} from "../theme"

const Features = (props) => {

    const {
        en = false
    } = props;

    return (
        <StaticQuery
            query={graphql`
            query FeatureQuery {
                bannerImage: file(relativePath: { eq: "images/containers.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 600, maxHeight: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
            `}
            render={data => {
                const banner = data.bannerImage;
                return (
                    <section style={{width: `100%`}} id={`features`}>
                        <Row
                            className={`pb-md-5 mb-md-5`}
                            noGutters
                        >
                            <Col md={4} className={`offset-md-8`}>
                                <SectionTitle
                                    title={en ? `Features` : `Χαρακτηριστικά`}
                                    onTop
                                />
                            </Col>
                        </Row>
                        <Container>
                            <Row noGutters>
                                <Col lg={5} className={`d-flex align-items-center`}>
                                    <Flexbox
                                        flexDirection={`column`}
                                        css={{
                                            paddingTop: largeSpacing,
                                            paddingBottom: largeSpacing,
                                            paddingRight: largeSpacing
                                        }}
                                    >
                                        <SectionFeature
                                            icon={<FaGlobe color={colorAccent} size={`3rem`}/>}
                                            title={en ? `Presence in all major ports worldwide.` : `Παρουσία σε όλα τα μεγάλα λιμάνια παγκοσμίως.`}
                                        />
                                        <SectionFeature
                                            icon={<Typography size={1.5} color={colorAccent}>20+</Typography>}
                                            title={en ? `More than 20 years of experience.` : `Περισσότερα από 20 χρόνια εμπειρίας.`}
                                        />
                                        <SectionFeature
                                            icon={<FaChartBar color={colorAccent} size={`3rem`}/>}
                                            title={en ? `Competitive rates.` : `Ανταγωνιστικές τιμές.`}
                                        />
                                        <SectionFeature
                                            icon={<FaUser color={colorAccent} size={`3rem`}/>}
                                            title={en ? `Great level of customer service.` : `Άρτια εξυπηρέτηση πελατών, φιλικό και καλά εκπαιδευμένο προσωπικό.`}
                                        />
                                    </Flexbox>
                                </Col>
                                <Col lg={7}>
                                    <Img fluid={banner.childImageSharp.fluid} alt='port' style={{maxWidth: `100%`}}/>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                )
            }}
        />
    )
};

export default Features