import React, {useState} from 'react'
import {graphql, StaticQuery} from 'gatsby'
import Img from 'gatsby-image'
import Flexbox from 'flexbox-react'
import styled from '@emotion/styled'
import Swiper, {Navigation} from 'react-id-swiper/lib/ReactIdSwiper.full'
import 'react-id-swiper/src/styles/css/swiper.css'
import {Container, Row, Col} from 'reactstrap'

import {
    MdKeyboardArrowLeft,
    MdKeyboardArrowRight
} from 'react-icons/md'

import {
    Typography,
    SectionService
} from '../components'
import {
    baseSpacing,
    colorAccent,
    colorWhite,
    largeSpacing,
    mediumSpacing,
    textLight
} from '../theme'

const Overlay = styled.div({
    position: `absolute`,
    left: `0`,
    right: `0`,
    top: `0`,
    bottom: `0`,
    color: colorWhite,
    background: `rgba(0,0,0,.4)`,
    height: `33rem`,
    zIndex: 2
});

const OverlayArrow = styled.div({
    width: 0,
    height: `100%`,
    position: `absolute`,
    top: 0,
    bottom: 0,
    left: `100%`,
    pointerEvents: `none`,
    borderBottom: `33rem solid transparent`,
    borderLeft: `15rem solid rgba(0,0,0,.4)`
});

const SlideItem = styled.div({
    backgroundColor: `#FFF`,
    opacity: `0 !important`,
    '&.swiper-slide-active': {
        opacity: `1 !important`
    }
});

const SwiperPagination = styled.button({
    // width: `3rem`,
    // height: `3rem`,
    padding: `${baseSpacing} ${mediumSpacing}`,
    border: `none`,
    cursor: `pointer`,
    '&:nth-child(even)': {
        color: colorAccent,
        backgroundColor: colorWhite
    },
    '&:nth-child(odd)': {
        color: colorWhite,
        backgroundColor: colorAccent
    },
    '& svg': {
        width: `100%`,
        height: `100%`
    },
    '&.swiper-button-disabled': {
        opacity: `.35`,
        cursor: `auto`,
        pointerEvents: `none`,
    }
});

const SwiperArrow = styled.button({
    width: `3rem`,
    height: `3rem`,
    border: `none`,
    position: `absolute`,
    top: 0,
    cursor: `pointer`,
    '& svg': {
        width: `100%`,
        height: `100%`
    },
    '&.swiper-button-disabled': {
        opacity: `.35`,
        cursor: `auto`,
        pointerEvents: `none`,
    }
});

const Slider = (props) => {
    const [swiper, updateSwiper] = useState(null);

    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };

    const goTo = id => {
        if (swiper !== null) {
            swiper.slideTo(id);
        }
    };

    const params = {
        autoplay: '200',
        slidesPerView: 1,
        // loop: 'true',
        grabCursor: 'true',
        effect: 'fade',
        navigation: {
            nextEl: '.swiper-button-next-btn',
            prevEl: '.swiper-button-prev-btn'
        },
    };

    const edges = props.data.allWordpressWpSlider.edges;
    const en = props.en;
    let slides;
    if (en) {
        slides = edges.filter(slide => slide.node.polylang_current_lang === 'en_US')
    } else {
        slides = edges.filter(slide => slide.node.polylang_current_lang !== 'en_US')
    }

    return (
        <div style={{width: `100%`}}>
            <Row noGutters>
                <Col lg={5}
                     style={{
                         pointerEvents: `none`
                     }}
                >
                    <Overlay>
                        <OverlayArrow className={`d-none d-lg-block`}/>
                        <Flexbox
                            css={{
                                height: `100%`,
                                paddingLeft: largeSpacing,
                                paddingRight: largeSpacing
                            }}
                            justifyContent={`center`}
                            flexDirection={`column`}
                        >
                            <Typography
                                component={'h2'}
                                size={3}
                                style={{
                                    maxWidth: `20rem`,
                                    fontWeight: 300
                                }}
                                gutterBottom
                            >
                                {en ? `We create value for our customers` : `Δημιουργούμε αξία για τους πελάτες μας`}
                            </Typography>
                            <Typography
                                component={'h3'}
                                size={1.4}
                                style={{
                                    fontWeight: 300,
                                    lineHeight: '2rem',
                                }}
                            >
                                {en ? `MK Transport offers competitive and reliable international freight towarding innovative solutions to support custommers expansion and growth.` : `Η MK Transport προσφέρει ανταγωνιστικές και αξιόπιστες διεθνείς μεταφορές, εφαρμόζοντας καινοτόμες λύσεις, για να υποστηρίζει τους πελάτες της στην περαιτέρω επιχειρηματική εξάπλωση και ανάπτυξή τους.`}
                            </Typography>
                        </Flexbox>
                    </Overlay>
                </Col>
                <Col lg={12} className={`d-flex align-items-center justify-content-center`}>
                    <Flexbox
                        alignItems={`center`}
                        justifyContent={`center`}
                        css={{
                            zIndex: 2,
                            position: `absolute`,
                            left: `50%`,
                            width: `100%`,
                            transform: `translateX(-50%)`,
                            '@media screen and (max-width: 992px)':{
                                top: `30rem`,
                            },
                            '@media screen and (min-width: 992px)':{
                                top: `28rem`,
                            }
                        }}
                    >
                        <div className={`d-none d-lg-block`}>
                            <span
                                style={{
                                    display: `block`,
                                    width: `100%`,
                                    textAlign: `center`,
                                    color: colorWhite,
                                    marginBottom: baseSpacing
                                }}
                            >
                                {en ? `Navigate to our services` : `Πλοήγηση στις υπηρεσίες μας`}
                            </span>
                            <div>
                                {slides && slides.map((item, key) => {
                                    const slide = item.node;
                                    return (
                                        <SwiperPagination key={key} onClick={() => goTo(key)}>
                                            {slide.title}
                                        </SwiperPagination>
                                    )
                                })}
                            </div>
                        </div>
                        <div className={`d-block d-lg-none`} style={{position: `relative`}}>
                            <SwiperArrow
                                className={`swiper-button-next-btn`}
                                role='button'
                                aria-label='next-slide-button'
                                onClick={goPrev}
                                style={{
                                    right: `.8rem`,
                                    color: colorWhite,
                                    backgroundColor: colorAccent,
                                    '&:hover': {
                                        color: colorAccent,
                                        backgroundColor: colorWhite,
                                    }
                                }}
                            >
                                <div style={{
                                    width: 0,
                                    height: `100%`,
                                    display: `block`,
                                    position: `absolute`,
                                    top: 0,
                                    bottom: 0,
                                    left: `100%`,
                                    borderTop: `3rem solid transparent`,
                                    borderLeft: `1.5rem solid ${colorAccent}`
                                }}/>
                                <MdKeyboardArrowLeft/>
                            </SwiperArrow>
                            <SwiperArrow
                                className={`swiper-button-prev-btn`}
                                role='button'
                                aria-label='previous-slide-button'
                                onClick={goNext}
                                style={{
                                    left: `.8rem`,
                                    color: colorAccent,
                                    backgroundColor: colorWhite,
                                    '&:hover': {
                                        color: colorWhite,
                                        backgroundColor: colorAccent,
                                    }
                                }}
                            >
                                <div style={{
                                    width: 0,
                                    height: `100%`,
                                    display: `block`,
                                    position: `absolute`,
                                    top: 0,
                                    bottom: 0,
                                    right: `100%`,
                                    borderBottom: `3rem solid transparent`,
                                    borderRight: `1.5rem solid ${colorWhite}`
                                }}/>
                                <MdKeyboardArrowRight/>
                            </SwiperArrow>
                        </div>
                    </Flexbox>
                </Col>
            </Row>
            <Swiper
                getSwiper={updateSwiper}
                modules={[Navigation]}
                {...params}
            >
                {slides && slides.map((item, key) => {
                    const slide = item.node;
                    const fluidImage = slide.featured_media ? slide.featured_media.localFile.childImageSharp.fluid : null;

                    const slideChildren = slide.fields.childNodes;
                    let childfluidImage = null;
                    if (slideChildren.length > 0)
                        childfluidImage = slideChildren[0].featured_media ? slideChildren[0].featured_media.localFile.childImageSharp.fluid : null;

                    return (
                        <SlideItem key={key} css={{width: `100%`}}>
                            {fluidImage
                                ?
                                <Img
                                    css={{
                                        position: `absolute`,
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        height: `33rem`
                                    }}
                                    imgStyle={{
                                        objectFit: `cover`,
                                        width: `100%`,
                                        height: `33rem`
                                    }}
                                    fluid={fluidImage}
                                    alt={slide.title}
                                />
                                : ''}
                            <SectionService
                                sectionTitle={`Services`}
                                icon={slide.meta_fields.spirit_slider_svg}
                                title={slide.title}
                                description={slide.content}
                            >
                                {slideChildren.length > 0 &&
                                <>
                                    <Container className={`pt-4 pb-4`}>
                                        <Typography
                                            component={'h4'}
                                            color={textLight}
                                            size={2.8}
                                            style={{fontWeight: 300}}
                                            gutterBottom
                                            dangerouslySetInnerHTML={{__html: slideChildren[0].title}}
                                        />
                                        <Typography dangerouslySetInnerHTML={{__html: slideChildren[0].content}}/>
                                    </Container>
                                    {fluidImage
                                        ?
                                        <Img
                                            fluid={childfluidImage}
                                            alt={slideChildren[0].title}
                                        />
                                        : ''}
                                </>
                                }
                            </SectionService>
                        </SlideItem>
                    )
                })}
            </Swiper>
        </div>
    )
};

export default ({en = false, ...props}) => (
    <StaticQuery
        query={graphql`
        query SliderQuery {
          allWordpressWpSlider(filter: {wordpress_parent: {eq: 0}, status: {eq: "publish"}}, sort: {fields: [date], order: ASC}, limit: 25) {
            edges {
              node {
                id
                wordpress_id
                title
                content
                polylang_current_lang
                meta_fields{
                    spirit_slider_svg
                }
                wordpress_parent
                featured_media{
                  localFile{
                      childImageSharp {
                        fluid(maxWidth: 1920, maxHeight: 420, cropFocus: CENTER){
                           ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                  }
                }
                fields {
                  childNodes {
                    title
                    content
                    meta_fields{
                        spirit_slider_svg
                    }
                    featured_media{
                      localFile{
                        childImageSharp {
                          fluid(maxWidth: 1920, maxHeight: 250, cropFocus: CENTER) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
    `}
        render={data => <Slider data={data} en={en} {...props}/>}
    />
)