import React from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import axios from 'axios'
import {graphql, StaticQuery} from 'gatsby'
import 'react-id-swiper/src/styles/css/swiper.css'
import {Container, Row, Col} from 'reactstrap'

import {
    SectionTitle, Typography
} from '../components'

import {
    baseSpacing,
    colorAccent,
    colorWhite,
    largeSpacing,
    mediumSpacing,
} from "../theme"

const Label = styled.label({
    color: colorWhite,
    display: `flex`,
    marginBottom: mediumSpacing,
    flexDirection: `column`,
    '& select': {
        marginTop: baseSpacing,
        height: `2.5rem`,
        borderRadius: `0`
    },
    '& input': {
        marginTop: baseSpacing,
        height: `2.5rem`,
        borderRadius: `0`
    }
});
const Button = styled.button({
    borderRadius: `0`,
    border: `none`,
    textTransform: `uppercase`,
    color: colorWhite,
    backgroundColor: colorAccent,
    height: `3rem`,
    padding: `${baseSpacing} ${largeSpacing}`,
    '&:hover': {
        opacity: `.8`
    }
});

class RequestRates extends React.Component {

    state = {
        formData: {},
        sending: false,
        send: false,
        message: '',
        showMessage: false
    };

    handleInputChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            formData: {...this.state.formData, ...{[name]: value}},
        });
        console.log({
            'freight-type': this.state.formData['freight-type'],
            'your-email': this.state.formData['your-email'],
            'your-subject': this.state.formData['your-subject'],
            'your-message': this.state.formData['your-message'],
            'your-name': this.state.formData['your-name'],
        })
    };

    handleSubmit = event => {
        event.preventDefault();
        const params = new URLSearchParams();
        params.append('freight-type', this.state.formData['freight-type']);
        params.append('your-email', this.state.formData['your-email']);
        params.append('your-subject', this.state.formData['your-subject']);
        params.append('your-message', this.state.formData['your-message']);
        params.append('your-name', this.state.formData['your-name']);
        axios.post(`https://mk.readytogo.gr/wp-json/contact-form-7/v1/contact-forms/10/feedback`, params)
            .then(response => {
                this.setState({
                    send: response.data.status === 'mail_sent',
                    showMessage: true,
                    message: response.data.message
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    render() {

        const {
            title = 'Request Rates',
            en = false
        } = this.props;

        return (
            <StaticQuery
                query={graphql`
                {
                  wordpressPage(slug: {eq: "contact"}) {
                    id
                    wordpress_id
                    title
                    featured_media{
                      localFile{
                          childImageSharp {
                            fluid(maxWidth: 1920){
                                ...GatsbyImageSharpFluid_withWebp
                            }
                          }
                      }
                    }
                  }
                }
            `}
                render={data => {
                    const page = data.wordpressPage;
                    const fluidImage = page.featured_media ? page.featured_media.localFile.childImageSharp.fluid : null;
                    return (
                        <section
                            style={{
                                width: `100%`,
                                position: `relative`
                            }}
                            id={`contact`}
                        >
                            {fluidImage
                                ?
                                <Img
                                    css={{
                                        position: `absolute !important`,
                                        left: 0,
                                        right: 0,
                                        top: 0,
                                        bottom: 0

                                    }}
                                    fluid={fluidImage}
                                    alt={page.title}
                                />
                                : ''}
                            <Row
                                className={`pb-md-5 mb-md-3`}
                                noGutters
                            >
                                <Col md={4} className={`offset-md-8`}>
                                    <SectionTitle
                                        title={en ? title : `Επικοινωνία`}
                                        onTop
                                    />
                                </Col>
                            </Row>
                            <Container>
                                <Row
                                    className={`p-md-5`}
                                    noGutters
                                >
                                    <form
                                        className={`p-3 p-md-5`}
                                        style={{
                                            flex: `1`,
                                            zIndex: `1`
                                        }}
                                        onSubmit={this.handleSubmit}
                                    >
                                        <Row>
                                            <Col md={6}>
                                                <Label>
                                                    Freight Type
                                                    <select
                                                        name="freight-type"
                                                        value={this.state.formData.type}
                                                        onChange={this.handleInputChange}
                                                    >
                                                        <option value="ocean">Ocean</option>
                                                        <option value="road">Road</option>
                                                        <option value="air">Air</option>
                                                    </select>
                                                </Label>
                                            </Col>
                                            <Col md={6}>
                                                <Label>
                                                    {en ? 'Company' : 'Εταιρία'}
                                                    <input
                                                        type="text"
                                                        name="your-subject"
                                                        placeholder={en ? `Enter your company name` : 'Η επωνυμία της εταιρείας σας'}
                                                        value={this.state.formData.subject}
                                                        onChange={this.handleInputChange}
                                                    />
                                                </Label>
                                            </Col>
                                            <Col md={6}>
                                                <Label>
                                                    {en ? `Name` : 'Όνομα'}
                                                    <input
                                                        type="text"
                                                        name="your-name"
                                                        placeholder={en ? `Enter your name` : 'Το όνομά σας'}
                                                        value={this.state.formData.name}
                                                        onChange={this.handleInputChange}
                                                    />
                                                </Label>
                                            </Col>
                                            <Col md={6}>
                                                <Label>
                                                    Email
                                                    <input
                                                        type="text"
                                                        name="your-email"
                                                        placeholder={en ? `Enter your email` : 'Το email σας'}
                                                        value={this.state.formData.email}
                                                        onChange={this.handleInputChange}
                                                    />
                                                </Label>
                                            </Col>
                                            <Col>
                                                <Label>
                                                    {en ? `Additional notes` : 'Μήνυμά'}
                                                    <textarea
                                                        name="your-message"
                                                        rows={5}
                                                        placeholder={en ? `Enter your message` : 'Το μήνυμά σας'}
                                                        onChange={this.handleInputChange}
                                                    >{this.state.formData.message}</textarea>
                                                </Label>
                                            </Col>
                                        </Row>
                                        <Row className={`d-flex justify-content-md-end flex-wrap`} noGutters>
                                            {this.state.showMessage === true &&
                                            <Typography
                                                color={this.state.send ? 'green' : 'red'}
                                                style={{
                                                    padding: baseSpacing,
                                                    marginRight: mediumSpacing,
                                                    marginBottom: mediumSpacing,
                                                    backgroundColor: `#ccc`
                                                }}
                                            >{this.state.message}</Typography>
                                            }
                                            <Button type="submit">{en ? `Submit` : 'Αποστολή'}</Button>
                                        </Row>
                                    </form>
                                </Row>
                            </Container>
                        </section>
                    )
                }}
            />
        )
    }
}

export default RequestRates