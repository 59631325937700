import React from 'react'
import {graphql, StaticQuery} from 'gatsby'
import 'react-id-swiper/src/styles/css/swiper.css'
import {Container, Row, Col} from 'reactstrap'

import {
    Typography,
    SectionTitle
} from '../components'

import {
    colorAccent,
    largeSpacing,
    textLight
} from "../theme"

const About = ({data, en = false}) => {
    const page = en ? data.enPage : data.elPage;
    return (
        <section style={{width: `100%`}} id={`about`}>
            <Row
                className={`pb-md-5 mb-md-3`}
                noGutters
            >
                <Col md={4} className={`offset-md-8`}>
                    <SectionTitle
                        title={en ? `About Us` : 'Σχετικά με εμάς'}
                        onTop
                    />
                </Col>
            </Row>
            <Container>
                <Row noGutters>
                    <Typography
                        size={3.5}
                        style={{fontWeight: 300}}
                        color={textLight}
                        gutterBottom
                    >
                        {page.title}
                        <div style={{
                            width: `40%`,
                            height: `.1rem`,
                            backgroundColor: colorAccent,
                            opacity: .5,
                            marginTop: `2rem`
                        }}/>
                    </Typography>
                    <div
                        style={{
                            paddingTop: largeSpacing,
                            paddingBottom: largeSpacing
                        }}
                        dangerouslySetInnerHTML={{__html: page.content}}
                    />
                </Row>
            </Container>
        </section>
    )
};

export default ({en = false, ...props}) => (
    <StaticQuery
        query={graphql`
            query AboutPage{
              enPage: wordpressPage(slug: {eq: "about"}) {
                id
                wordpress_id
                slug
                title
                content
                _links{
                  wp_attachment{
                    href
                  }
                }
              }
              elPage: wordpressPage(slug: {eq: "about-el"}) {
                id
                wordpress_id
                slug
                title
                content
                _links{
                  wp_attachment{
                    href
                  }
                }
              }
            }
    `}
        render={data => <About data={data} en={en} {...props}/>}
    />
)