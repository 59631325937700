import React, {Component} from 'react'
import {Layout} from '../../layouts'
import {
    Slider,
    Features,
    RequestRates,
    About
} from '../../containers'

class Home extends Component {
    render() {
        return (
            <Layout location={this.props.location} en isHome full>
                <Slider en/>
                <Features en/>
                <About en/>
                <RequestRates en/>
            </Layout>
        )
    }
}

export default Home
